body {
  background-color: aliceblue;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.singleSpellDetails {
  margin-top: 8rem;
  font-weight: 500;
}
.singleSpellDetails p {
  margin-top: 1rem;
}

.singleSpellDetails h1 {
  color: darkcyan;
}

.homePageBtn {
  margin-top: 3rem;
  height: 30px;
  width: 200px;
  background: #fff;
  outline: 1px solid gray;
  border: none;
  border-radius: 20px;
  margin-right: -5px;
}

.homePageBtn:hover {
  background: gray;
  outline: 1px solid gray;
  border: none;
  border-radius: 20px;
  margin-right: -5px;
}
